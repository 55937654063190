@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

@import 'styles/variables';
@import 'styles/resets';

@import 'styles/material-theme';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'styles/borders';
@import 'styles/card';
@import 'styles/buttons';
@import 'styles/code';
@import 'styles/animations';
@import 'styles/headers';
@import 'styles/typography';
@import 'styles/elevation';

@import 'styles/form-controls';

body {
    margin: 0;
    padding: 0;

    font-family: 'Roboto', sans-serif;
    color: var(--colors-text-dark);

    background-color: var(--colors-background-gray);
}

.rotated-icon {
    transform: rotate(90deg);
    padding-right: 5px;
}

.mat-snack-bar-container {
    background-color: white;
    color: black;
}
