h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0.5rem 0;
}

h3 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.75rem;
    color: $colors-light-gray;
}

h4 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.55rem;
    color: $colors-light-gray;
}

h5 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.35rem;
    color: $colors-light-gray;
}

h6 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.1rem;
    color: $colors-light-gray;
}
