.pressable {
    @apply transition;
    @apply shadow;

    &:hover {
        @apply shadow-md;
        background-color: var(--colors-background-gray);
    }

    &:active {
        transform: translateY(1px) scale(0.99);
        @apply shadow;
    }
}
