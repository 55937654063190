small {
    font-size: 0.8em;
}

a {
    cursor: pointer;

    transition: all 0.3s;

    color: var(--colors-brand-primary);
    text-decoration: none;

    line-height: 1em;

    &:hover {
        text-decoration: underline;
    }
}
