* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 0.6rem;
    width: 0.6rem;
}

::-webkit-scrollbar-thumb {
    border: solid 2px white;
    border-radius: 0.4rem;
    background: var(--colors-background-gray-dark);
}

::-webkit-scrollbar-track {
    background: #a9a9a924;
    border: solid 2px white;
    border-radius: 0.4rem;
}
