div.card {
    background: #ffffff;

    border: 1px solid #dee5e4;
    border-radius: 6px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.16);

    padding: 0.6375rem;

    overflow: hidden;
}
