.btn {
    @apply rounded px-4 py-3 shadow-md transition duration-200 ease-in-out select-none;

    width: fit-content;

    font-weight: 500;

    font-family: 'Roboto', sans-serif;

    &[disabled] {
        filter: grayscale(45%);
        opacity: 0.8;
        cursor: not-allowed;
    }

    // to remove ugly ios highlight upon tap
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover:not([disabled]) {
        @apply bg-opacity-90;
    }

    &:active:not(.disabled) {
        transform: scale(0.97);
        @apply shadow-sm bg-opacity-95;
    }
}

.btn-sm {
    @apply text-sm px-2 py-1;

    mat-icon {
        @apply text-sm px-2 py-1;
        padding: 0px;
    }
}


.btn-md {
    @apply text-base px-3 py-2;
}

.btn-block {
    width: 100%;
}

.btn-warning {
    background-color: #f17a27;

    color: theme('colors.tinted-gray.900');
}

.btn-error {
    @apply bg-red-600;

    color: white;
    mat-icon {
        padding: 0px;
    }
}

.btn-primary {
    background-color: #4581b3;

    color: white;
}

.btn-secondary {
    @apply bg-gray-600;

    color: var(--colors-text-dark);
}

.action-btn {
    @apply flex items-center cursor-pointer;

    justify-content: center;

    padding: 4px;
    margin: 5px;

    transition: all 0.2s;

    width: 30px;
    height: 30px;

    will-change: transform, box-shadow;

    border: 2px solid var(--colors-warning);
    border-radius: 8px;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

    &.mute {
        border-color: var(--colors-light-gray);
    }

    &:not([disabled]):hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    }
    &:disabled {
        opacity: 0.5;
    }

    &:active {
        transform: translateY(1px);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    }

    fa-icon {
        color: var(--colors-warning);

        &.mute {
            color: var(--colors-light-gray);
        }
    }
}
